<template>
  <div>
    <b-sidebar
      sidebar-class="sidebar-lg"
      visible
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @change="closeModal"
    >
      <template #default="{ hide }">

        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5
            class="mb-0"
          >
            {{ $t('Opções de seleção') }}
          </h5>
          <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              :size="sizeIconsAction"
              @click="hide"
            />
          </div>
        </div>

        <b-container
          class="p-2"
          fluid
        >

          <b-button
            v-if="userIsAgent===true && showBtnTransfer === true"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            block
            @click.stop.prevent="closeModal();changeTabLead(filterSelected.leadsID.value)"
          >
            <span class="align-middle">{{ $t('Transferir separador') }}</span>
          </b-button>

          <b-button
            v-if="showBtnBookmarks === true"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            block
            @click.stop.prevent="closeModal();addBookmarks(filterSelected.leadsID.value)"
          >
            <span class="align-middle">{{ $t('Adicionar marcadores') }}</span>
          </b-button>

          <b-button
            v-if="userHaveTeam===true && showBtnChangeResp === true"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            block
            @click.stop.prevent="closeModal();assignRemoveExchange(filterSelected.leadsID.value)"
          >
            <span class="align-middle">{{ $t('Atribuir, remover ou trocar responsável') }}</span>
          </b-button>

          <b-button
            v-if="showBtnDelete === true"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            block
            @click.stop.prevent="closeModal();remove(filterSelected.leadsID.value)"
          >
            <span class="align-middle">{{ $t('Eliminar') }}</span>
          </b-button>

        </b-container>

      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar, BButton, BContainer,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { sizeIconsAction } from '@core-custom/mixins/geral'
import checkNested from 'check-nested'
import { mapGetters } from 'vuex'

export default {
  components: {
    BSidebar,
    BButton,
    BContainer,
  },
  directives: {
    Ripple,
  },
  mixins: [sizeIconsAction],
  props: {
    remove: {
      type: Function,
      required: true,
    },
    addBookmarks: {
      type: Function,
      required: true,
    },
    changeTabLead: {
      type: Function,
      required: true,
    },
    assignRemoveExchange: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      showBtnTransfer: false,
      showBtnBookmarks: false,
      showBtnChangeResp: false,
      showBtnDelete: false,
    }
  },
  computed: {
    ...mapGetters('crm_leads', ['filterSelected', 'tabSelectFilter']),
    userIsAgent() {
      if (this.tabSelectFilter === 'contactos') {
        return false
      }

      const oCurrentUser = this.$store.getters['auth/currentUser']
      if (checkNested(oCurrentUser, 'id_role') && ([4, 7].includes(oCurrentUser.id_role))) {
        return true
      }

      return false
    },
    userHaveTeam() {
      if (this.tabSelectFilter === 'contactos') {
        return false
      }

      const oCurrentUser = this.$store.getters['auth/currentUser']
      if (checkNested(oCurrentUser, 'extras.basic') && (oCurrentUser.extras.basic === 1)) {
        return false
      }
      if (checkNested(oCurrentUser, 'extras.idLeader') && (oCurrentUser.extras.idLeader !== '')) {
        return true
      }

      return false
    },
  },
  async mounted() {
    const oCurrentUser = this.$store.getters['auth/currentUser']
    if (oCurrentUser !== null && oCurrentUser !== undefined && checkNested(oCurrentUser, 'extras.basic') && oCurrentUser.extras.basic === 1) {
      this.showBtnTransfer = true
      this.showBtnBookmarks = true
      this.showBtnChangeResp = true
      this.showBtnDelete = true
    } else if (oCurrentUser !== null && oCurrentUser !== undefined) {
      if ('extras' in oCurrentUser && ('perm' in oCurrentUser.extras)) {
        if ((Object.values(oCurrentUser.extras.perm).includes('altera_favoritos_leads'))) {
          this.showBtnBookmarks = true
        }

        if ((Object.values(oCurrentUser.extras.perm).includes('pode_transferir_separador'))) {
          this.showBtnTransfer = true
        }

        if (this.tabSelectFilter !== 'lixo' && (Object.values(oCurrentUser.extras.perm).includes('pode_atribuir') || Object.values(oCurrentUser.extras.perm).includes('pode_enviar_leads_outros_sep'))) {
          this.showBtnChangeResp = true
        }

        if (Object.values(oCurrentUser.extras.perm).includes(`${this.tabSelectFilter}_A`)) {
          this.showBtnDelete = true
        }

        if (Object.values(oCurrentUser.extras.perm).includes('lixo_V')) {
          this.showBtnBookmarks = true
        }
      }
    }
  },
  methods: {
    closeModal() {
      this.$parent.closeModalActionByMultipleRows()
    },
  },
}
</script>
